<template>
    <div class="block-section">
        <Toast />
        <div class="block-content">
            <div class="surface-ground min-h-screen px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                <div class="surface-card py-3 p-4 shadow-2 border-round w-full md:w-6">
                    <div class="text-center mb-4">
                        <img src="../assets/logo-light-512.png" alt="Nishcinto Logo" height="100">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome to Nishcinto!</div>
                        <span class="text-600 font-medium line-height-3">Signin to Nishcinto</span>
                    </div>

                    <form @submit.prevent="signin(!valid$.$invalid)" class="p-fluid">
                        <div class="grid">
                            <div class="field col-12">
                                <span class="p-float-label p-fluid">
                                    <InputText
                                        class="p-inputtext-lg"
                                        :class="{'p-invalid': valid$.email.$invalid && submitted}"
                                        id="email1"
                                        type="text"
                                        v-model="valid$.email.$model"
                                    />
                                    <label for="email1" class="block text-900 font-medium mb-2">Email</label>
                                </span>
                                <span v-if="valid$.email.$error && submitted">
                                    <span
                                        id="email-error"
                                        v-for="(error, index) of valid$.email.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "E-mail")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.email.$invalid && submitted) || valid$.email.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.email.required.$message.replace("Value", "E-mail") }}
                                </small>
                            </div>

                            <div class="field col-12">
                                <span class="p-float-label p-fluid">
                                    <Password
                                        class="p-inputtext-lg"
                                        :class="{'p-invalid': valid$.password.$invalid && submitted}"
                                        id="password"
                                        toggleMask
                                        :feedback="false"
                                        v-model="valid$.password.$model"
                                    />
                                    <label for="password" class="block text-900 font-medium mb-2">Password</label>
                                </span>
                                <span v-if="valid$.password.$error && submitted">
                                    <span
                                        id="password-error"
                                        v-for="(error, index) of valid$.password.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Password")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.password.$invalid && submitted) || valid$.password.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.password.required.$message.replace("Value", "Password") }}
                                </small>
                            </div>

                            <div class="field col-12">
                                <div class="flex align-items-center justify-content-between mb-4  w-full">
                                    <span class="flex flex-auto font-medium no-underline ml-2 text-blue-500 text-left cursor-pointer" @click="forgotOpen">Forgot password?</span>
                                    <Button label="Signin" icon="pi pi-sign-in" iconPos="right" class="flex flex-1" :loading="loading" type="submit"></Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            
            
            <Dialog
                header="Recover Password"
                v-model:visible="forgotDialog"
                :breakpoints="{'1800px': '50vw', '1200px': '80vw', '800px': '90vw'}"
                :style="{width: '50vw'}"
                :modal="true"
            >
                <div class="text-center mb-5">
                    <img src="../assets/logo-light-512.png" alt="Nishcinto Logo" height="100">
                    <div>
                        <span class="text-600 font-medium line-height-3">Please enter your email to get password recovery link</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="field col-12">
                        <span class="p-float-label p-fluid">
                            <InputText
                                class="p-inputtext-lg"
                                :class="{'p-invalid': forgotValid$.email.$invalid && submittedFP}"
                                id="email1"
                                type="text"
                                v-model="forgotValid$.email.$model"
                            />
                            <label for="email1" class="block text-900 font-medium mb-2">Email</label>
                        </span>
                        <span v-if="forgotValid$.email.$error && submittedFP">
                            <span
                                id="email-error"
                                v-for="(error, index) of forgotValid$.email.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "E-mail")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(forgotValid$.email.$invalid && submittedFP) || forgotValid$.email.$pending.$response"
                            class="p-error"
                        >
                            {{ forgotValid$.email.required.$message.replace("Value", "E-mail") }}
                        </small>
                    </div>
                </div>
                <template #footer>
                    <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                    <Button label="Submit" icon="pi pi-check" class="p-button p-button-raised" @click="forgotSubmit(!forgotValid$.$invalid)" :loading="loadingFP"/>
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const toast = useToast();

        const state = reactive({
            email : "",
            password : "",
        });

        const rules = {
            email : { required, email },
            password : { required , minLength : minLength(6)},
        };

        const valid$ = useVuelidate (rules, state);

        const forgotState = reactive({
            email : '',
        });

        const forgotRules = {
            email : { required, email },
        };

        const forgotValid$ = useVuelidate (forgotRules, forgotState);

        const submitted = ref(false);
        const submittedFP = ref(false);
        const loading = ref(false);
        const loadingFP = ref(false);
        const forgotDialog = ref(false);
        const massage = ref(null);

        const signin = (isFormValid) => {
            loading.value = true;
            submitted.value = true;
            if (!isFormValid) {
                loading.value = false;
                return;
            }
            Axios
                .post("api/auth/operator/signin", {
                    email: state.email,
                    password: state.password,
                })
                .then((res) => {
                    loading.value = false;
                    if (res.data.response == "success") {
                        var currentUser = {
                            id: res.data.data.id,
                            role: res.data.data.role,
                            name: res.data.data.name,
                            email: res.data.data.email,
                            agency_id: res.data.data.agency_id,
                            photo: res.data.data.photo,
                            address: res.data.data.address,
                            dob: res.data.data.dob,
                            nid: res.data.data.nid,
                            mobile: res.data.data.mobile,
                            token: res.data.data.token,
                        };
                        store.dispatch(
                            "setUserData",
                            JSON.stringify(currentUser)
                        );
                        router.replace('/operator');
                    } else {
                        massage.value = res.data.message;
                        toast.add({severity:'error', summary: 'Error Message', detail: res.data.message, life: 5000});
                    }
                })
                .catch((err) => {
                    loading.value = false;
                    console.log(err);
                    toast.add({severity:'error', summary: 'Error Message', detail: err, life: 5000});
                });
        };

        const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

        const forgotOpen = () => {
            forgotDialog.value = true;
        };

        const hideDialog = () => {
            forgotDialog.value = false;

            forgotState.email = '';
        };

        const forgotSubmit = (isFormValid) => {
            submittedFP.value = true;
            if (!isFormValid) {
                return;
            }
            loadingFP.value = true;

            Axios
            .post('api/auth/operator/forgot-password', {
                email: forgotState.email
            })
            .then( (res) => {
                loadingFP.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					forgotDialog.value = false;
                    router.replace({name: 'reset-code', query: {email: forgotState.email} });
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res);
                }
            })
            .catch((err) => {
                showToast("error", "Error", err.message);
                console.log(err);
            });
        }

        return {
            valid$,
            forgotValid$,
            submitted,
            submittedFP,
            loading,
            loadingFP,
            signin,
            email,
            massage,
            router,
            forgotDialog,
            forgotOpen,
            hideDialog,
            forgotSubmit
        }
    },


}
</script>

<style scoped>

</style>